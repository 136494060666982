import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import ExportDialog from '@/views/home/my-selection-table/export-dialog/export-dialog.vue';
import FilterDialog from '@/views/home/my-selection-table/filter-dialog/filter-dialog.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import PiexUtils from '@/shared/utils/piexUtils';

const logger = new Logger('login');
const articleModule = namespace('article');

@Component({
  components: { D4yTable, ExportDialog, FilterDialog },
})
export default class MySelectionTable extends Vue {
  @articleModule.Action('getArticles')
  private actionGetArticles!: any;
  @articleModule.Getter('getArticles')
  private getArticles!: any;
  @articleModule.Getter('getArticlesIsLoading')
  private articlesIsLoading!: boolean;
  @articleModule.Getter('getArticlesSearchParams')
  private articlesSearchParams!: any;

  @articleModule.Action('getArticlesWarengruppe')
  private actionGetArticlesWarengruppe!: any;
  @articleModule.Getter('getArticlesWarengruppe')
  private getArticlesWarengruppe!: any;

  @articleModule.Getter('getSearchData')
  private getSearchData!: any;

  private searchPayload: any = {};
  @Watch('getSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    logger.debug(`----filter:${oldVal}->${newVal}`);
    let payload = {
      searchData: {
        anwendungsszenarien: newVal.anwendungsszenarien?.map((x: any) => x.name),
        produktgruppen: newVal.produktgruppen,
        warengruppen: PiexUtils.takeNumberFromWarengruppe(newVal.warengruppe),
        lieferantenNummers: newVal.lieferantenNummers,
        modelNames: newVal.modelnames,
        articleNames: newVal.articlenames,
        deltaExport: newVal.deltaexport,

        preisbindungBis: newVal.preisbindungBis,
        lastUpdateOn: newVal.lastUpdateOn,
        erstelltAm: newVal.erstelltAm,
        farbeSuchfarbe: newVal.farbeSuchfarbe?.map((x: any) => x.name),
        aktionsware: newVal.aktionsware?.map((x: any) => x.name),
        isArticlesWithAllStatuses: newVal.isArticlesWithAllStatuses,
      },
    };
    this.searchPayload = payload.searchData;
    await this.actionGetArticles(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get tableText() {
    return `${this.getArticles.total} Artikel`;
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
    await this.getArticlesData();
  }

  private async getArticlesData() {
    // don't take getter value in this case
    await this.actionGetArticles()
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  // item = {
  //   supplier: 'ALFONS VENJAKOB GMBH & CO. KG MOEBELFABRIK',
  //   name: 'Beistelltisch Höhe K 49 cm_1800 Filz-Tablett aufgelegt',
  //   van: '123456',
  //   ean: '123456',
  //   han: '123456',
  //   preisbindung: '2022-04-14',
  // };
  // items = Array(10).fill(this.item);

  get items() {
    return this.getArticles.items;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('supplier'), value: 'lieferantName' },
      { text: this.$t('model_name'), value: 'modellname' },
      { text: this.$t('article_name'), value: 'name' },
      { text: this.$t('van'), value: 'verbandsArtikelNummer' },
      { text: this.$t('ean'), value: 'ean' },
      { text: this.$t('price_binding'), value: 'preisbindungBis' },
    ];
    return headers;
  }

  private async selectionUpdateOptions(newVal: any, oldVal: any) {
    this.articlesSearchParams.dataOption = newVal;
    this.articlesSearchParams.filter = newVal.filter;
    if (this.isMounted && !this.articlesIsLoading) this.getArticlesWithParams();
  }
  private async getArticlesWithParams() {
    await this.actionGetArticles({ searchParams: this.articlesSearchParams, searchData: this.searchPayload });
  }

  get isHasSearchData() {
    return this.getArticles.total === 0 || this.articlesIsLoading || this.isSearchDataHasNoValues();
  }

  isSearchDataHasNoValues() {
    let values = Object.values(this.getSearchData);
    let result = values.some((x: any) => (x + '').length > 0);
    return !result;
  }

  //#region Export-dialog (Zum Refinament )
  dialogExport = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogExport.show = false;
    },
  };

  showExportDialog() {
    logger.log('show-export-dialog');
    this.dialogExport.show = true;
  }
  //#endregion

  //#region Filter-dialog
  dialogFilter = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogFilter.show = false;
    },
  };

  showFilterDialog() {
    logger.log('show-filter-dialog');
    this.dialogFilter.show = true;
  }

  showFilter() {
    this.dialogFilter.show = true;
  }
  //#endregion
}
