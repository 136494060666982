import searchData from '@/shared/model/smallPayloadModels/searchData';
import { ArticleState } from './types';

export const state = (): ArticleState => initialState();

export const initialState = (): ArticleState => ({
  searchData: searchData.defaultData(),
  articlesProductGroups: [],
  articlesWarengruppe: [],
  articlesModelNames: [],
  articlesNames: [],
  articlesSuppliers: [],
  articles: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name', 'verbandsArtikelNummer', 'lieferantName', 'ean', 'modellname'],
    },
  },
});
