import { Component, Vue } from 'vue-property-decorator';
import SearchForm from '@/views/home/search-form/search-form.vue'; // @ is an alias to /src
import MySelectionTable from '@/views/home/my-selection-table/my-selection-table.vue'; // @ is an alias to /src
import GeneralUtils from '@/shared/utils/generalUtils';

@Component({
  components: {
    SearchForm,
    MySelectionTable,
  },
})
export default class Home extends Vue {
  mounted() {
    if (!GeneralUtils.isDevEnvironment()) {
      let hubspotScript = document.createElement('script');
      hubspotScript.setAttribute('id', 'hs-script-loader');
      hubspotScript.setAttribute('src', 'https://js.hs-scripts.com/14524674.js');
      document.head.appendChild(hubspotScript);
    } else {
      console.log('THIS IS DEV ENV');
    }
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }
}
