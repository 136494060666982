export interface ExportTemplate {
    id: string; // int
    name: string; // string
    fields: string; // string
    fieldsCount: number; // int
    createdById: string; // System.Guid
    createdByName: string; // string
    createdByEmail: string; // string
    selectionId: string; // System.Guid?
    // selection: piex.DataAccessPostgreSQL.Database.DataModel.SelectionDm?; // piex.DataAccessPostgreSQL.Database.DataModel.SelectionDm?

}

export interface Value {
  value: ExportTemplate[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ExportTemplate>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Fields: data?.fields || '',
    FieldsCount: data?.fieldsCount || 0,
    CreatedById: data?.createdById || undefined,
    CreatedByName: data?.createdByName || undefined,
    CreatedByEmail: data?.createdByEmail || undefined,
    SelectionId: data?.selectionId || undefined,
    // Selection: data?.selection || '',
  };
}

function parse(data?: Partial<ExportTemplate>): ExportTemplate {
  return {
    id: data?.id || '',
    name: data?.name || '',
    fields: data?.fields || '',
    fieldsCount: data?.fieldsCount || 0,
    createdById: data?.createdById || '',
    createdByName: data?.createdByName || '',
    createdByEmail: data?.createdByEmail || '',
    selectionId: data?.selectionId || '',
    // selection: data?.selection || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
