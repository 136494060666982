import { Logger } from 'fsts';
const logger = new Logger('generalUtils');

// From https://github.com/mattphillips/deep-object-diff
export const isDate = (d: any) => d instanceof Date;
export const isEmpty = (o: any) => Object.keys(o).length === 0;
export const isObject = (o: any) => o != null && typeof o === 'object';
export const properObject = (o: any) =>
  isObject(o) && !o.hasOwnProperty ? { ...o } : o;

// Returns only the values that have been changed in the updated object
const updatedDiff = (lhs: any, rhs: any) => {
  if (lhs === rhs) return {};

  if (!isObject(lhs) || !isObject(rhs)) return rhs;

  const l = properObject(lhs);
  const r = properObject(rhs);

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {};
    return r;
  }

  return Object.keys(r).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (l.hasOwnProperty(key)) {
      const difference: any = updatedDiff(l[key], r[key]);

      if (isObject(difference) && isEmpty(difference) && !isDate(difference))
        return acc;

      return { ...acc, [key]: difference };
    }

    return acc;
  }, {});
};

export default class GeneralUtils {
  public static isDevEnvironment(): boolean {
    return process.env.NODE_ENV == 'development' || window.location.host.includes('localhost');
  }
  public static isProdEnvironment(): boolean {
    return process.env.NODE_ENV == 'production';
  }
  public static camelToSnakeCase(str: string) {
    let result = str
      .replace(/^[A-Z]/g, letter => `${letter.toLowerCase()}`)
      .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    logger.debug(`camelToSnake:${str}->${result}`);
    return result;
  }
  public static maxint = 2147483647;
  public static updatedDiff = (lhs: any, rhs: any) => {
    return updatedDiff(lhs, rhs);
  };
}
