import sel, { Selection } from './selection';

export interface ExportAutomatic {
  id: string; // System.Guid
  name: string; // string
  exportType: string; // Enum.ExportType;
  exportTime: string; // Enum.ExportTime;
  exportPlace: string; // Enum.ExportPlace;
  exportFileFormat: string; // Enum.ExportFileFormat;
  exportAmount: string; // Enum.ExportAmount;
  nextExportDate: string; // System.DateTime
  selectionId: string; // System.Guid
  selection: Selection;
  createdAt: string; // System.DateTime

  activeExportTemplateId: string | null; // System.Guid
  customTemplateName: string;
}

export interface Value {
  value: ExportAutomatic[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ExportAutomatic>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    ExportType: data?.exportType || '',
    ExportTime: data?.exportTime || '',
    ExportPlace: data?.exportPlace || '',
    ExportFileFormat: data?.exportFileFormat || '',
    ExportAmount: data?.exportAmount || '',
    NextExportDate: data?.nextExportDate || undefined,
    SelectionId: data?.selectionId || undefined,
    Selection: data?.selection ? sel.toAPI(data?.selection) : undefined,
    CreatedAt: data?.createdAt || undefined,
    ActiveExportTemplateId: data?.activeExportTemplateId || undefined,
    CustomTemplateName: data?.customTemplateName || '',
  };
}

function parse(data?: Partial<ExportAutomatic>): ExportAutomatic {
  return {
    id: data?.id || '',
    name: data?.name || '',
    exportType: data?.exportType || '',
    exportTime: data?.exportTime || '',
    exportPlace: data?.exportPlace || '',
    exportFileFormat: data?.exportFileFormat || '',
    exportAmount: data?.exportAmount || '',
    nextExportDate: data?.nextExportDate || '',
    selectionId: data?.selectionId || '',
    selection: data?.selection || sel.parse({}),
    createdAt: data?.createdAt || '',
    activeExportTemplateId: data?.activeExportTemplateId || null,
    customTemplateName: data?.customTemplateName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
