import { MutationTree } from 'vuex';
import { ColorState } from './types';
import color, { Color, Value } from '@/shared/model/color';

export const mutations: MutationTree<ColorState> = {
  setColors(state, payload: Value) {
    state.colors.items = payload.value.map((x) => color.parse(x));
    state.colors.total = payload['@odata.count'] || 0;
    state.colors.isLoading = false;
  },
  setColorsTotal(state, payload: number) {
    state.colors.total = payload;
  },
  setColorsIsLoading(state, payload: boolean) {
    state.colors.isLoading = payload;
  },
};
