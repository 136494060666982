import { ActionTree } from 'vuex';
import { ColorState } from './types';
import { RootState } from '../../types';
import { defaultBackendColor } from '@/shared/backend/color';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import color, { Value, Color } from '@/shared/model/color';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.colors');
export const actions: ActionTree<ColorState, RootState> = {
  getColors({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setColorsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getColorsSearchParams;
    return defaultBackendColor
      .getColors(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setColors', response.data);
        commit('setColorsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setColorsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getColor({ commit, dispatch }, id: string) {
    return defaultBackendColor
      .getColor(id)
      .then((response: AxiosResponse<Color>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateColor({ commit, dispatch }, file: Color) {
    return defaultBackendColor
      .updateColor(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Color;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.color_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteColor({ commit, dispatch }, id: string) {
    return defaultBackendColor
      .deleteColor(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Color;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
