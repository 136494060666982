import { GetterTree } from 'vuex';
import { ExportTemplateState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ExportTemplateState, RootState> = {
  ['getExportTemplates']: (state) => state.exportTemplates,
  ['getExportTemplatesIsLoading']: (state) => state.exportTemplates?.isLoading,
  ['getExportTemplatesTotal']: (state) => state.exportTemplates?.total,
  ['getExportTemplatesSearchParams']: (state) => state.exportTemplates?.searchParams,
};
