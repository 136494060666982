export interface SearchData {
  anwendungsszenarien: string[];
  produktgruppen: string[];
  lieferantenNummers: number[];
  warengruppe: string[];
  modelnames: string[];
  articlenames: string[];
  deltaexport: any; // date

  preisbindungBis: any; // date
  lastUpdateOn: any; // date  // `Änderungsdatum` in `Piex` Figma layout
  erstelltAm: any; // date  // Anlagedatum in `Piex` Figma layout
  farbeSuchfarbe: string[];
  aktionsware: string[];
  isArticlesWithAllStatuses: boolean;
}

function defaultData(): SearchData {
  return {
    anwendungsszenarien: [],
    produktgruppen: [],
    lieferantenNummers: [],
    warengruppe: [],
    modelnames: [],
    articlenames: [],
    deltaexport: undefined,

    preisbindungBis: undefined,
    lastUpdateOn: undefined,
    erstelltAm: undefined,
    farbeSuchfarbe: [],
    aktionsware: [],
    isArticlesWithAllStatuses: false,
  };
}

export default {
  defaultData,
};
