import { render, staticRenderFns } from "./search-form.html?vue&type=template&id=1bd58c10&scoped=true&"
import script from "./search-form.ts?vue&type=script&lang=ts&"
export * from "./search-form.ts?vue&type=script&lang=ts&"
import style0 from "./search-form.scss?vue&type=style&index=0&id=1bd58c10&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd58c10",
  null
  
)

/* custom blocks */
import block0 from "./search-form.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fsearch-form%2Fsearch-form.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VForm,VSpacer})
