import { GetterTree } from 'vuex';
import { ArticleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ArticleState, RootState> = {
  ['getArticles']: (state) => state.articles,
  ['getArticlesIsLoading']: (state) => state.articles?.isLoading,
  ['getArticlesTotal']: (state) => state.articles?.total,
  ['getArticlesSearchParams']: (state) => state.articles?.searchParams,

  ['getArticlesProductGroups']: (state) => state.articlesProductGroups,
  ['getArticlesWarengruppe']: (state) => state.articlesWarengruppe,
  ['getArticlesModelNames']: (state) => state.articlesModelNames,
  ['getArticlesNames']: (state) => state.articlesNames,
  ['getArticlesSuppliers']: (state) => state.articlesSuppliers,
  ['getSearchData']: (state) => state.searchData,
};
