import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import exportArchive, { ExportArchive, Value } from '../model/exportArchive';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ExportArchive');
export interface BackendExportArchive {
  getExportArchive(id: string): AxiosPromise<ExportArchive>;
  downloadArchive(data: any): AxiosPromise<any>;
  getExportArchives: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteExportArchive(id: string): AxiosPromise;
  updateExportArchive(ExportArchive: ExportArchive): AxiosPromise<any>;
}

export const defaultBackendExportArchive: BackendExportArchive = {
  getExportArchive(id: string): AxiosPromise<ExportArchive> {
    let url = `${URLS.exportArchiveOdata}/${id}`;
    return instance.get<ExportArchive>(url);
  },
  getExportArchives(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.exportArchiveOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    url = `${url}&$expand=ModelMarkers,articleMarkers,colors`;
    logger.log(`getExportArchives${url}`);
    return instance.get<Value>(url);
  },
  downloadArchive(data: any): AxiosPromise<any> {
    logger.debug('downloadArchive');
    return instance.put<any>(`${URLS.exportArchive}/downloadArchive`, data, {
      responseType: 'blob',
    });
  },

  deleteExportArchive(id: string): AxiosPromise {
    logger.debug('deleteExportArchive');
    return instance.delete(`${URLS.exportArchive}/${id}`);
  },
  updateExportArchive(ExportArchive: ExportArchive): AxiosPromise<any> {
    logger.debug('updateExportArchive');
    return instance.put<ExportArchive>(`${URLS.exportArchive}/update`, exportArchive.toAPI(ExportArchive));
  },
};
