import { MutationTree } from 'vuex';
import { ExportArchiveState } from './types';
import exportArchive, { ExportArchive, Value } from '@/shared/model/exportArchive';

export const mutations: MutationTree<ExportArchiveState> = {
  setExportArchives(state, payload: Value) {
    state.exportArchives.items = payload.value.map((x) => exportArchive.parse(x));
    state.exportArchives.total = payload['@odata.count'] || 0;
    state.exportArchives.isLoading = false;
  },
  setExportArchivesTotal(state, payload: number) {
    state.exportArchives.total = payload;
  },
  setExportArchivesIsLoading(state, payload: boolean) {
    state.exportArchives.isLoading = payload;
  },
};
