import { GetterTree } from 'vuex';
import { SelectionState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SelectionState, RootState> = {
  ['getSelections']: (state) => state.selections,
  ['getSelectionsIsLoading']: (state) => state.selections?.isLoading,
  ['getSelectionsTotal']: (state) => state.selections?.total,
  ['getSelectionsSearchParams']: (state) => state.selections?.searchParams,
};
