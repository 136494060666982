import { ActionTree } from 'vuex';
import { ExportArchiveState } from './types';
import { RootState } from '../../types';
import { defaultBackendExportArchive } from '@/shared/backend/exportArchive';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import exportArchive, { Value, ExportArchive } from '@/shared/model/exportArchive';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('actions.exportArchives');
export const actions: ActionTree<ExportArchiveState, RootState> = {
  getExportArchives({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setExportArchivesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getExportArchivesSearchParams;
    return defaultBackendExportArchive
      .getExportArchives(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setExportArchives', response.data);
        commit('setExportArchivesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setExportArchivesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getExportArchive({ commit, dispatch }, id: string) {
    return defaultBackendExportArchive
      .getExportArchive(id)
      .then((response: AxiosResponse<ExportArchive>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  async downloadArchive({ commit, dispatch }, data: any) {
    try {
      const response = await defaultBackendExportArchive.downloadArchive(data);
      if (response.headers['content-disposition']) {
        const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: type });
        const url = DownloadUtils.createDownloadLink(blob, fileName);
        window.URL.revokeObjectURL(url);
      }
      return response.data;
    } catch (e: any) {
      console.log('e :>> ', e);
      if (e.response.status === 404) {
        module_utils.error(i18n.tc(`error.file_not_found`), commit, e, logger);
      } else {
        module_utils.error('error', commit, e, logger);
      }

      throw e;
    }
  },
  updateExportArchive({ commit, dispatch }, file: ExportArchive) {
    return defaultBackendExportArchive
      .updateExportArchive(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ExportArchive;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.exportArchive_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteExportArchive({ commit, dispatch }, id: string) {
    return defaultBackendExportArchive
      .deleteExportArchive(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ExportArchive;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
