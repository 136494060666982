import { SelectionState } from './types';

export const state = (): SelectionState => initialState();

export const initialState = (): SelectionState => ({
  selections: {
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name', 'filters'],
    },
  },
});
