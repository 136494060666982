import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import exportAutomatic, { ExportAutomatic, Value } from '../model/exportAutomatic';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ExportAutomatic');
export interface BackendExportAutomatic {
  getExportAutomatic(id: string): AxiosPromise<ExportAutomatic>;
  getExportAutomatics: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteExportAutomatic(id: string): AxiosPromise;
  updateExportAutomatic(ExportAutomatic: ExportAutomatic): AxiosPromise<any>;
}

export const defaultBackendExportAutomatic: BackendExportAutomatic = {
  getExportAutomatic(id: string): AxiosPromise<ExportAutomatic> {
    let url = `${URLS.exportAutomaticOdata}/${id}`;
    return instance.get<ExportAutomatic>(url);
  },
  getExportAutomatics(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('or');
    if (searchParams.filter) {
      odfb.contains('toLower(name)', `${searchParams.filter.toLowerCase()}`);
      odfb.contains('selection/filters', `${searchParams.filter.toLowerCase()}`);
      odfb.contains('toLower(exportType)', `${searchParams.filter.toLowerCase()}`);
    }
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.exportAutomaticOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    url = `${url}&$expand=Selection($expand=ModelMarkers,articleMarkers,colors)`;
    logger.log(`getExportAutomatics${url}`);
    return instance.get<Value>(url);
  },

  deleteExportAutomatic(id: string): AxiosPromise {
    logger.debug('deleteExportAutomatic');
    return instance.delete(`${URLS.exportAutomatic}/${id}`);
  },
  updateExportAutomatic(ExportAutomatic: ExportAutomatic): AxiosPromise<any> {
    logger.debug('updateExportAutomatic');
    return instance.put<ExportAutomatic>(`${URLS.exportAutomatic}/update`, exportAutomatic.toAPI(ExportAutomatic));
  },
};
