import { MutationTree } from 'vuex';
import { ExportTemplateState } from './types';
import Vue from 'vue';
import exportTemplate, { ExportTemplate, Value } from '@/shared/model/exportTemplate';

export const mutations: MutationTree<ExportTemplateState> = {
  setExportTemplates(state, payload: Value) {
    state.exportTemplates.items = payload.value.map((x) => exportTemplate.parse(x));
    state.exportTemplates.total = payload['@odata.count'] || 0;
    state.exportTemplates.isLoading = false;
  },
  setExportTemplatesTotal(state, payload: number) {
    state.exportTemplates.total = payload;
  },
  setExportTemplatesIsLoading(state, payload: boolean) {
    state.exportTemplates.isLoading = payload;
  },
};
