import { ROUTES } from '@/router/routesEnum';
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import sel, { Selection } from '@/shared/model/selection';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import DateField from '@/components/_common/date-field/DateField.vue';

const logger = new Logger('filter-dialog');
const articleModule = namespace('article');
const modelMarkersModule = namespace('modelMarker');
const colorsModule = namespace('color');

@Component({
  name: 'filter-dialog',
  components: { DateField },
})
export default class FilterDialog extends Vue {
  @Ref('observer-filter-dialog-form')
  private observerFilterDialogForm!: any;

  @articleModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;
  @articleModule.Getter('getSearchData')
  private getSearchData!: SearchData;

  @modelMarkersModule.Action('getModelMarkers')
  private actionGetModelMarkers!: any;
  @modelMarkersModule.Getter('getModelMarkers')
  private getArticleModelMarkers!: any;

  @colorsModule.Action('getColors')
  private actionGetColors!: any;
  @colorsModule.Getter('getColors')
  private getterColors!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  useCases = [];
  data: any = [];
  date: any = '';

  get colors() {
    return this.getterColors?.items;
  }

  searchData = searchData.defaultData();

  async mounted() {
    this.searchData = Object.assign({}, this.getSearchData);
    var promiseAll = [this.getColors(), this.getModelMarkers()];
    await Promise.all(promiseAll);
  }

  private async getModelMarkers() {
    if (this.getArticleModelMarkers?.length > 0) {
      this.useCases = this.getArticleModelMarkers;
    } else {
      await this.actionGetModelMarkers()
        .then((result: any) => {
          this.useCases = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private async getColors() {
    if (this.getterColors?.length > 0) {
      // this.colors = this.colors.value;
    } else {
      await this.actionGetColors()
        .then((result: any) => {})
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  isSaving = false;
  async filter() {
    const result = await this.observerFilterDialogForm.validate();

    this.actionUpdateSearchData(this.searchData)
      .then(() => {
        this.clickClose();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  changeAnderungDate(value: any) {
    this.searchData.lastUpdateOn = value || undefined; // new Date(value).toJSON();
  }
  changeAnlageDate(value: any) {
    this.searchData.erstelltAm = value || undefined; // new Date(value).toJSON();
  }
  changePriceDate(value: any) {
    this.searchData.preisbindungBis = value || undefined; // new Date(value).toJSON();
  }

  clickClose() {
    this.$emit('click:close');
  }

  searchColorInput = '';
  searchModelMakerInput = '';

  clearSearchInput() {
    this.searchColorInput = '';
    this.searchModelMakerInput = '';
  }
}
