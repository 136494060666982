import { GetterTree } from 'vuex';
import { ColorState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ColorState, RootState> = {
  ['getColors']: (state) => state.colors,
  ['getColorsIsLoading']: (state) => state.colors?.isLoading,
  ['getColorsTotal']: (state) => state.colors?.total,
  ['getColorsSearchParams']: (state) => state.colors?.searchParams,
};
