import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import exportTemplate, { ExportTemplate, Value } from '../model/exportTemplate';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ExportTemplate');
export interface BackendExportTemplate {
  getExportTemplate(id: string): AxiosPromise<ExportTemplate>;
  getExportTemplates: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteExportTemplate(id: string): AxiosPromise;
  updateExportTemplate(ExportTemplate: ExportTemplate): AxiosPromise<any>;
}

export const defaultBackendExportTemplate: BackendExportTemplate = {
  getExportTemplate(id: string): AxiosPromise<ExportTemplate> {
    let url = `${URLS.exportTemplateOdata}/${id}`;
    return instance.get<ExportTemplate>(url);
  },
  getExportTemplates(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.exportTemplateOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getExportTemplates${url}`);
    return instance.get<Value>(url);
  },

  deleteExportTemplate(id: string): AxiosPromise {
    logger.debug('deleteExportTemplate');
    return instance.delete(`${URLS.exportTemplate}/${id}`);
  },
  updateExportTemplate(ExportTemplate: ExportTemplate): AxiosPromise<any> {
    logger.debug('updateExportTemplate');
    return instance.put<ExportTemplate>(`${URLS.exportTemplate}/update`, exportTemplate.toAPI(ExportTemplate));
  },
};
