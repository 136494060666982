import { ActionTree } from 'vuex';
import { SelectionState } from './types';
import { RootState } from '../../types';
import { defaultBackendSelection } from '@/shared/backend/selection';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import selection, { Value, Selection } from '@/shared/model/selection';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.selections');
export const actions: ActionTree<SelectionState, RootState> = {
  getSelections({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setSelectionsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getSelectionsSearchParams;
    return defaultBackendSelection
      .getSelections(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setSelections', response.data);
        commit('setSelectionsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setSelectionsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getSelection({ commit, dispatch }, id: string) {
    return defaultBackendSelection
      .getSelection(id)
      .then((response: AxiosResponse<Selection>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateSelection({ commit, dispatch }, file: Selection) {
    return defaultBackendSelection
      .updateSelection(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Selection;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.selection_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteSelection({ commit, dispatch }, id: string) {
    return defaultBackendSelection
      .deleteSelection(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Selection;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
