import { GetterTree } from 'vuex';
import { ExportArchiveState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ExportArchiveState, RootState> = {
  ['getExportArchives']: (state) => state.exportArchives,
  ['getExportArchivesIsLoading']: (state) => state.exportArchives?.isLoading,
  ['getExportArchivesTotal']: (state) => state.exportArchives?.total,
  ['getExportArchivesSearchParams']: (state) => state.exportArchives?.searchParams,
};
