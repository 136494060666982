import { Component, Vue } from 'vue-property-decorator';
import DialogBoxComponent from '@/components/dialogBox/dialogBox';
import { Action, Getter, namespace, State } from 'vuex-class';
import { Logger } from 'fsts';
import { ROUTES } from '@/router/routesEnum';
import { msalInstance } from 'vue-msal-browser';
import GeneralUtils from '@/shared/utils/generalUtils';

const logger = new Logger('app');
const authModule = namespace('auth');

@Component({
  components: {},
})
export default class App extends Vue {
  public $refs!: Vue['$refs'] & {
    dialogBox: DialogBoxComponent;
  };
  @State('snackbar')
  private snackbar!: any; //Snackbar
  @Action('hideSnackbar') private releaseSnackbar: any;

  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;
  @authModule.Getter('getUser')
  private user!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Action('logout')
  private logoutAction: any;
  @authModule.Action('updateAzureData')
  private updateAzureData: any;

  get mobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  drawer = false;
  toggleMini = true;
  maxHeight = 32;
  maxWidth = 35;

  header = 'select';

  leftMenuItems: {
    src: string;
    translateId?: string;
    to?: string;
  }[] = [
    {
      src: 'ico_select',
      translateId: 'select',
      to: '/',
    },
    {
      src: 'ico_export',
      translateId: 'export',
      to: '/export',
    },
    {
      src: 'ico_archive',
      translateId: 'archive',
      to: '/archive',
    },
  ];

  created() {
    logger.log('process.env :>> ', process.env);
    console.log('this.$router :>> ', this.$router);
    this.$msal
      .authenticateRedirect()
      .then((result: any) => {
        logger.log('Azure logging :>> ', result);
        this.updateAzureData(result);

        this.handleRedirectAfterAzureLogin();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private handleRedirectAfterAzureLogin() {
    const currentRoute = this.$router.currentRoute;
    const redirectPath = currentRoute.query.redirect;
    if (currentRoute.name == ROUTES.login && redirectPath) {
      this.$router.push({ path: redirectPath + '' });
    } else if (currentRoute.name == ROUTES.login) {
      this.$router.push({ name: ROUTES.home });
    }
  }

  mounted() {
    // placing a global component in app.vue.html and adding ref options (e.g. <myComponent ref="mycomp"></myComponent>)
    // then make a ref global:
    this.$confirm.open = this.$refs.dialogBox.openConfirm;
    this.$message.open = this.$refs.dialogBox.openMessageBox;
  }

  setHeader(value: string) {
    this.header = value;
  }

  get hasAzureUser() {
    return this.azureUser?.name;
  }

  async logout() {
    // await this.logoutAction();
    await this.$msal.logoutRedirect();

    this.$router.push({ name: ROUTES.login });
  }
}
