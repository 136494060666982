import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import DateField from '@/components/_common/date-field/DateField.vue';

const logger = new Logger('search-form');
const articleModule = namespace('article');
const articleMarkersModule = namespace('articleMarker');

@Component({
  components: { DateField },
})
export default class SearchForm extends Vue {
  @Ref('productGroupCombobox')
  private refProductGroupCombobox!: any;
  @Ref('suppliersCombobox')
  private refSuppliersCombobox!: any;
  @Ref('warengruppeCombobox')
  private refWarengruppeCombobox!: any;
  @Ref('modelnamesCombobox')
  private refModelNamesCombobox!: any;
  @Ref('articlenamesCombobox')
  private refArticleNamesCombobox!: any;

  @articleModule.Action('getArticlesProductGroups')
  private actionGetArticlesProductGroups!: any;
  @articleModule.Getter('getArticlesProductGroups')
  private getArticlesProductGroups!: any;

  @articleModule.Action('getArticlesSuppliers')
  private actionGetArticlesSuppliers!: any;
  @articleModule.Getter('getArticlesSuppliers')
  private getArticlesSuppliers!: any;

  @articleModule.Action('getArticlesWarengruppe')
  private actionGetArticlesWarengruppe!: any;
  @articleModule.Getter('getArticlesWarengruppe')
  private getArticlesWarengruppe!: any;

  @articleModule.Action('getArticlesModelNames')
  private actionGetArticlesModelNames!: any;
  @articleModule.Getter('getArticlesModelNames')
  private getArticlesModelNames!: any;

  @articleModule.Action('getArticlesNames')
  private actionGetArticlesNames!: any;
  @articleModule.Getter('getArticlesNames')
  private getArticlesNames!: any;

  @articleModule.Action('resetSearchFormData')
  private actionResetSearchFormData!: any;
  @articleModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;
  @articleModule.Getter('getSearchData')
  private getSearchData!: any;

  @articleMarkersModule.Action('getArticleMarkers')
  private actionGetArticleMarkers!: any;
  @articleMarkersModule.Getter('getArticleMarkers')
  private articleMarkers!: any;

  async mounted() {
    var promiseAll = [
      this.getArticleMarkers(),
      this.getProductGroups(),
      this.getSuppliers(),
      this.getWarengruppe(),
      this.getModelNames(),
    ];
    await Promise.all(promiseAll);
  }

  @Watch('searchData', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    this.actionUpdateSearchData(this.searchData);
  }

  private loadingSuppliers = false;
  private async getSuppliers() {
    if (this.getArticlesSuppliers?.length > 0) {
      this.suppliers = this.getArticlesSuppliers;
    } else {
      this.loadingSuppliers = true;
      await this.actionGetArticlesSuppliers()
        .then((result: any) => {
          this.suppliers = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;
        });
    }
  }

  private loadingUseCases = false;
  private async getArticleMarkers() {
    if (this.articleMarkers?.length > 0) {
      this.useCases = this.articleMarkers.value;
    } else {
      this.loadingUseCases = true;
      await this.actionGetArticleMarkers()
        .then((result: any) => {
          this.useCases = result.value;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingUseCases = false;
        });
    }
  }

  private loadingProductGroups = false;
  private async getProductGroups() {
    if (this.getArticlesProductGroups?.length > 0) {
      this.productGroups = this.getArticlesProductGroups;
    } else {
      this.loadingProductGroups = true;
      await this.actionGetArticlesProductGroups()
        .then((result: any) => {
          this.productGroups = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingProductGroups = false;
        });
    }
  }

  private loadingWarengruppe = false;
  private async getWarengruppe() {
    if (this.getArticlesWarengruppe?.length > 0) {
      this.materialGroups = this.getArticlesWarengruppe;
    } else {
      this.loadingWarengruppe = true;
      await this.actionGetArticlesWarengruppe()
        .then((result: any) => {
          this.materialGroups = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingWarengruppe = false;
        });
    }
  }

  private loadingModelNames = false;
  private async getModelNames() {
    if (this.getArticlesModelNames?.length > 0) {
      this.modelNames = this.getArticlesModelNames;
    } else {
      this.loadingModelNames = true;
      await this.actionGetArticlesModelNames()
        .then((result: any) => {
          this.modelNames = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingModelNames = false;
        });
    }
  }

  private loadingArticlesNames = false;
  private async getArticleNames() {
    if (this.getArticlesNames?.length > 0) {
      this.articleNames = this.getArticlesNames;
    } else {
      this.loadingArticlesNames = true;
      await this.actionGetArticlesNames()
        .then((result: any) => {
          this.articleNames = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingArticlesNames = false;
        });
    }
  }

  searchData = searchData.defaultData();

  useCases = [];
  suppliers = [];
  materialGroups = [];
  modelNames = [];
  articleNames = [];
  productGroups = [];
  deltas = [];

  searchArticles() {
    var data = Object.assign({}, this.searchData); // avoid sending requests when change searchData (watcher in `my-selection-table` component)
    this.actionUpdateSearchData(data);
  }

  changeDate(value: any) {
    this.searchData.deltaexport = value || undefined; // new Date(value).toJSON();
  }

  //#region Filtering autocomplete data based on previous autocompletes
  private setSearchFormFiltersPayload() {
    let newVal = this.searchData;
    let payload = {
      anwendungsszenarien: newVal.anwendungsszenarien?.map((x: any) => x.name),
      produktgruppen: newVal.produktgruppen,
      warengruppen: newVal.warengruppe,
      lieferantenNummers: newVal.lieferantenNummers,
      modelNames: newVal.modelnames,
      articleNames: newVal.articlenames,
      isArticlesWithAllStatuses: newVal.isArticlesWithAllStatuses,
    };
    return payload;
  }

  showMenu() {
    this.refProductGroupCombobox.activateMenu();
  }

  changeProductGroups() {
    this.refProductGroupCombobox.lazySearch = '';
  }
  changeSupplier() {
    this.refSuppliersCombobox.lazySearch = '';
  }
  changeModelNames() {
    this.refModelNamesCombobox.lazySearch = '';
  }
  changeArticleNames() {
    this.refArticleNamesCombobox.lazySearch = '';
  }


  filterProductGroups() {
    if (this.searchData.anwendungsszenarien?.length > 0) {
      this.loadingProductGroups = true;
      let payload = this.setSearchFormFiltersPayload();

      this.actionGetArticlesProductGroups(payload)
        .then((result: any) => {
          console.log('result :>> ', result);
          this.productGroups = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingProductGroups = false;
          console.log('productGroupCombobox :>> ', this.refProductGroupCombobox);
          // this.refProductGroupCombobox.activateMenu();

          this.$nextTick(() => {
            this.refProductGroupCombobox.activateMenu();
          });
        });
    }
  }

  filterSupplier() {
    if (this.searchData.anwendungsszenarien?.length > 0 || this.searchData.produktgruppen?.length > 0) {
      this.loadingSuppliers = true;
      let payload = this.setSearchFormFiltersPayload();

      this.actionGetArticlesSuppliers(payload)
        .then((result: any) => {
          this.suppliers = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;

          this.$nextTick(() => {
            this.refSuppliersCombobox.activateMenu();
          });
        });
    }
  }

  filterWarengruppe() {
    if (
      this.searchData.anwendungsszenarien?.length > 0 ||
      this.searchData.produktgruppen?.length > 0 ||
      this.searchData.lieferantenNummers?.length > 0
    ) {
      this.loadingWarengruppe = true;
      let payload = this.setSearchFormFiltersPayload();

      this.actionGetArticlesWarengruppe(payload)
        .then((result: any) => {
          this.materialGroups = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingWarengruppe = false;

          this.$nextTick(() => {
            this.refWarengruppeCombobox.activateMenu();
          });
        });
    }
  }

  filterModelNames() {
    if (
      this.searchData.anwendungsszenarien?.length > 0 ||
      this.searchData.produktgruppen?.length > 0 ||
      this.searchData.lieferantenNummers?.length > 0 ||
      this.searchData.warengruppe?.length > 0
    ) {
      this.loadingModelNames = true;
      let payload = this.setSearchFormFiltersPayload();

      this.actionGetArticlesModelNames(payload)
        .then((result: any) => {
          this.modelNames = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingModelNames = false;

          this.$nextTick(() => {
            this.refModelNamesCombobox.activateMenu();
          });
        });
    }
  }

  filterArticleNames() {
    this.loadingArticlesNames = true;
    let payload = this.setSearchFormFiltersPayload();

    this.actionGetArticlesNames(payload)
      .then((result: any) => {
        this.articleNames = result;
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.loadingArticlesNames = false;

        this.$nextTick(() => {
          this.refArticleNamesCombobox.activateMenu();
        });
      });
  }
  //#endregion

  searchUseCaseInput = '';
  searchProductInput = '';
  searchSupplierInput = '';
  searchWarengruppeInput = '';
  searchModelNameInput = '';
  searchArticleNameInput = '';

  clearSearchInput() {
    this.searchUseCaseInput = '';
    this.searchProductInput = '';
    this.searchSupplierInput = '';
    this.searchWarengruppeInput = '';
    this.searchModelNameInput = '';
    this.searchArticleNameInput = '';
  }

  clearSearchFormData() {
    this.actionResetSearchFormData();

    this.clearSearchFormInputsWithSearchData();
  }

  clearSearchFormInputsWithSearchData() {
    this.clearSearchInput();

    this.searchData.anwendungsszenarien = [];
    this.searchData.produktgruppen = [];
    this.searchData.lieferantenNummers = [];
    this.searchData.warengruppe = [];
    this.searchData.modelnames = [];
    this.searchData.articlenames = [];
    //this.searchData.deltaexport = '';//
  }

  get isSearchFormHasData() {
    return (
      this.searchData.anwendungsszenarien.length > 0 ||
      this.searchData.produktgruppen.length > 0 ||
      this.searchData.lieferantenNummers.length > 0 ||
      this.searchData.warengruppe.length > 0 ||
      this.searchData.modelnames.length > 0 ||
      this.searchData.articlenames.length > 0 ||
      !!this.searchData.deltaexport
    );
  }
}
