import { ExportAutomaticState } from './types';

export const state = (): ExportAutomaticState => initialState();

export const initialState = (): ExportAutomaticState => ({
  exportAutomatics: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 0, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
