import { Logger } from 'fsts';
const logger = new Logger('piexUtils');

export default class PiexUtils {
  public static takeNumberFromWarengruppe(array: any) {
    // `Warengruppe` has the same template for all records (4 digits + 1 space + text)
    // so we will take only 4 digits for filtering  by `Warengruppe` field on backend
    const result = array.map((x: any) => x.slice(0, 4));
    return result;
  }
}
