import { MutationTree } from 'vuex';
import { SelectionState } from './types';
import selection, { Selection, Value } from '@/shared/model/selection';

export const mutations: MutationTree<SelectionState> = {
  setSelections(state, payload: Value) {
    state.selections.items = payload.value.map((x) => selection.parse(x));
    state.selections.total = payload['@odata.count'] || 0;
    state.selections.isLoading = false;
  },
  setSelectionsTotal(state, payload: number) {
    state.selections.total = payload;
  },
  setSelectionsIsLoading(state, payload: boolean) {
    state.selections.isLoading = payload;
  },
};
