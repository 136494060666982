import { GetterTree } from 'vuex';
import { ExportAutomaticState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ExportAutomaticState, RootState> = {
  ['getExportAutomatics']: (state) => state.exportAutomatics,
  ['getExportAutomaticsIsLoading']: (state) => state.exportAutomatics?.isLoading,
  ['getExportAutomaticsTotal']: (state) => state.exportAutomatics?.total,
  ['getExportAutomaticsSearchParams']: (state) => state.exportAutomatics?.searchParams,
};
