
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import color, { Color, Value } from '../model/color';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Color');
export interface BackendColor {
  getColor(id: string): AxiosPromise<Color>;
  getColors: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteColor(id: string): AxiosPromise;
  updateColor(Color: Color): AxiosPromise<any>;
}

export const defaultBackendColor: BackendColor = {
  getColor(id: string): AxiosPromise<Color> {
    let url = `${URLS.colorOdata}/${id}`;
    return instance.get<Color>(url);
  },
  getColors(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.colorOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getColors${url}`);
    return instance.get<Value>(url);
  },

  deleteColor(id: string): AxiosPromise {
    logger.debug('deleteColor');
    return instance.delete(`${URLS.color}/${id}`);
  },
  updateColor(Color: Color): AxiosPromise<any> {
    logger.debug('updateColor');
    return instance.put<Color>(`${URLS.color}/update`, color.toAPI(Color));
  },
};
