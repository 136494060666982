import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import selection, { Selection, Value } from '../model/selection';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Selection');
export interface BackendSelection {
  getSelection(id: string): AxiosPromise<Selection>;
  getSelections: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteSelection(id: string): AxiosPromise;
  updateSelection(Selection: Selection): AxiosPromise<any>;
}

export const defaultBackendSelection: BackendSelection = {
  getSelection(id: string): AxiosPromise<Selection> {
    let url = `${URLS.selectionOdata}/${id}`;
    return instance.get<Selection>(url);
  },
  getSelections(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.selectionOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getSelections${url}`);
    url = `${url}&$expand=ModelMarkers,articleMarkers,colors`;
    return instance.get<Value>(url);
  },

  deleteSelection(id: string): AxiosPromise {
    logger.debug('deleteSelection');
    return instance.delete(`${URLS.selection}/${id}`);
  },
  updateSelection(Selection: Selection): AxiosPromise<any> {
    logger.debug('updateSelection');
    return instance.put<Selection>(`${URLS.selection}/update`, selection.toAPI(Selection));
  },
};
