import { MutationTree } from 'vuex';
import { ArticleState } from './types';
import Vue from 'vue';
import article, { Article, Value } from '@/shared/model/article';

export const mutations: MutationTree<ArticleState> = {
  setArticles(state, payload: Value) {
    state.articles.items = payload.value.map((x) => article.parse(x));
    state.articles.total = payload['@odata.count'] || 0;
    state.articles.isLoading = false;
  },
  setArticlesTotal(state, payload: number) {
    state.articles.total = payload;
  },
  setArticlesIsLoading(state, payload: boolean) {
    state.articles.isLoading = payload;
  },

  setArticlesProductGroups(state, payload: any) {
    state.articlesProductGroups = payload;
  },
  setArticlesWarengruppe(state, payload: any) {
    state.articlesWarengruppe = payload;
  },
  setArticlesModelNames(state, payload: any) {
    state.articlesModelNames = payload;
  },
  setArticlesNames(state, payload: any) {
    state.articlesNames = payload;
  },
  setArticlesSuppliers(state, payload: any) {
    state.articlesSuppliers = payload;
  },
  setSearchData(state, payload: any) {
    state.searchData = payload;
  },
  resetSearchFormData(state, payload: any) {
    state.searchData.anwendungsszenarien = [];
    state.searchData.produktgruppen = [];
    state.searchData.lieferantenNummers = [];
    state.searchData.warengruppe = [];
    state.searchData.modelnames = [];
    state.searchData.articlenames = [];
    state.searchData.deltaexport = undefined;
  },
};
