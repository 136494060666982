import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import article, { Article, Value } from '../model/article';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Article');
export interface BackendArticle {
  getArticlesWarengruppe(searchData?: any): AxiosPromise<any>;
  getArticlesProductGroups(searchData?: any): AxiosPromise<any>;
  getArticlesAllSuppliers(searchData?: any): AxiosPromise<any>;
  getArticlesModelNames(searchData?: any): AxiosPromise<any>;
  getArticlesNames(searchData?: any): AxiosPromise<any>;
  getArticle(id: string): AxiosPromise<Article>;
  getArticles: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  deleteArticle(id: string): AxiosPromise;
  updateArticle(Article: Article): AxiosPromise<any>;

  getArticlesReport(data: any): AxiosPromise<any>;
  getArticlesSuppliersFiltered(data: any): AxiosPromise<any>;
}

export const defaultBackendArticle: BackendArticle = {
  getArticlesWarengruppe(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.article}/GetDistinctWarengruppen`;
    return instance.put<Value>(url, searchData);
  },
  getArticlesProductGroups(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.article}/GetDistinctProductGroups`;
    return instance.put<Value>(url, searchData);
  },
  getArticlesAllSuppliers(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.article}/GetAllDistinctSuppliers`;
    return instance.put<Value>(url, searchData);
  },
  getArticlesModelNames(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.article}/GetDistinctModelNames`;
    return instance.put<Value>(url, searchData);
  },
  getArticlesNames(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.article}/GetDistinctNames`;
    return instance.put<Value>(url, searchData);
  },
  getArticle(id: string): AxiosPromise<Article> {
    let url = `${URLS.articleOdata}/${id}`;
    return instance.get<Article>(url);
  },

  getArticles(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.articleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getArticles${url}`);
    url = `${url}&$select=Id,LieferantName,Name, VerbandsArtikelNummer, EAN, Modellname, PreisbindungBis`;
    return instance.put<Value>(url, searchData);
  },

  deleteArticle(id: string): AxiosPromise {
    logger.debug('deleteArticle');
    return instance.delete(`${URLS.article}/${id}`);
  },
  updateArticle(Article: Article): AxiosPromise<any> {
    logger.debug('updateArticle');
    return instance.put<Article>(`${URLS.article}/update`, article.toAPI(Article));
  },
  getArticlesReport(data: any): AxiosPromise<any> {
    logger.debug('updateArticle');
    return instance.put<any>(`${URLS.article}/GetArticleReport`, data, {
      responseType: 'blob',
    });
  },
  getArticlesSuppliersFiltered(data: any): AxiosPromise<any> {
    logger.debug('getArticlesSuppliersFiltered');
    return instance.put<any>(`${URLS.article}/GetDistinctSuppliers`, data);
  },
};
