import { ArticleMarker } from './articleMarker';
import { Color } from './color';
import { ModelMarker } from './modelMarker';

export interface Selection {
  id: string; // System.Guid
  name: string; // string
  filters: string; // string

  modelMarkerIds: any[];
  modelMarkers: ModelMarker[];
  articleMarkerIds: any[];
  articleMarkers: ArticleMarker[];
  colorIds: any[];
  colors: Color[];

  produktgruppen: string[]; // string[]
  lieferantenNummers: number[]; // int[]
  warengruppen: string[]; // string[]
  modelNames: string[]; // string[]
  articleNames: string[]; // string[]
  deltaExport: string; // System.DateTime?
  preisbindungBis: string; // System.DateTime?
  geaendertAm: string; // System.DateTime?
  erstelltAm: string; // System.DateTime?
  createdAt: string; // System.DateTime
  isArticlesWithAllStatuses: boolean;
}

export interface Value {
  value: Selection[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Selection>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Filters: data?.filters || '',

    ModelMarkerIds: data?.modelMarkerIds || [],
    ArticleMarkerIds: data?.articleMarkerIds || [],
    ColorIds: data?.colorIds || [],

    Produktgruppen: data?.produktgruppen || [],
    LieferantenNummers: data?.lieferantenNummers || [],
    Warengruppen: data?.warengruppen || [],
    ModelNames: data?.modelNames || [],
    ArticleNames: data?.articleNames || [],
    DeltaExport: data?.deltaExport || undefined,
    PreisbindungBis: data?.preisbindungBis || undefined,
    GeaendertAm: data?.geaendertAm || undefined,
    ErstelltAm: data?.erstelltAm || undefined,
    CreatedAt: data?.createdAt || undefined,
    IsArticlesWithAllStatuses: data?.isArticlesWithAllStatuses || false,
  };
}

function parse(data?: Partial<Selection>): Selection {
  return {
    id: data?.id || '',
    name: data?.name || '',
    filters: data?.filters || '',

    modelMarkerIds: data?.modelMarkerIds || [],
    modelMarkers: data?.modelMarkers || [],
    articleMarkerIds: data?.articleMarkerIds || [],
    articleMarkers: data?.articleMarkers || [],
    colorIds: data?.colorIds || [],
    colors: data?.colors || [],

    produktgruppen: data?.produktgruppen || [],
    lieferantenNummers: data?.lieferantenNummers || [],
    warengruppen: data?.warengruppen || [],
    modelNames: data?.modelNames || [],
    articleNames: data?.articleNames || [],
    deltaExport: data?.deltaExport || '',
    preisbindungBis: data?.preisbindungBis || '',
    geaendertAm: data?.geaendertAm || '',
    erstelltAm: data?.erstelltAm || '',
    createdAt: data?.createdAt || '',
    isArticlesWithAllStatuses: data?.isArticlesWithAllStatuses || false,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
