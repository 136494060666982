import { ROUTES } from '@/router/routesEnum';
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import sel, { Selection } from '@/shared/model/selection';
import { SearchData } from '@/shared/model/smallPayloadModels/searchData';

const logger = new Logger('export-dialog');
const selectionModule = namespace('selection');
const articleModule = namespace('article');

@Component({
  name: 'export-dialog',
  components: {},
})
export default class ExportDialog extends Vue {
  @Ref('observer-export-dialog-form')
  private observerExportDialogForm!: any;

  @selectionModule.Action('updateSelection')
  private actionUpdateSelection!: any;

  @articleModule.Getter('getSearchData')
  private getSearchData!: SearchData;

  @Prop({ default: false })
  private dialog!: boolean;

  selection: Selection = sel.parse({});

  isSaving = false;
  async clickSave() {
    const result = await this.observerExportDialogForm.validate();

    this.preparePayload();

    if (result) {
      this.isSaving = true;
      this.actionUpdateSelection(this.selection)
        .then((result: any) => {
          this.$router.push({ name: ROUTES.export });
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }

  private preparePayload() {
    logger.log('preparePayload for export :>> '); // test cache update
    this.selection.filters = this.populateFilterField();

    this.selection.articleMarkerIds = this.getSearchData.anwendungsszenarien!.map((x: any) => x.id);
    this.selection.produktgruppen = this.getSearchData.produktgruppen;
    this.selection.lieferantenNummers = this.getSearchData.lieferantenNummers;
    this.selection.warengruppen = this.getSearchData.warengruppe;
    this.selection.modelNames = this.getSearchData.modelnames;
    this.selection.articleNames = this.getSearchData.articlenames;
    this.selection.deltaExport = this.getSearchData.deltaexport;

    this.selection.preisbindungBis = this.getSearchData.preisbindungBis;
    this.selection.geaendertAm = this.getSearchData.lastUpdateOn;
    this.selection.erstelltAm = this.getSearchData.erstelltAm;
    this.selection.modelMarkerIds = this.getSearchData.aktionsware!.map((x: any) => x.id);
    this.selection.colorIds = this.getSearchData.farbeSuchfarbe!.map((x: any) => x.id);
    this.selection.isArticlesWithAllStatuses = this.getSearchData.isArticlesWithAllStatuses;
  }

  populateFilterField(): string {
    let filters: Array<string> = [];

    if (this.getSearchData.anwendungsszenarien?.length > 0) {
      filters.push('Anwendungsszenarien');
    }
    if (this.getSearchData.produktgruppen?.length > 0) {
      filters.push('Produktgruppen');
    }
    if (this.getSearchData.lieferantenNummers?.length > 0) {
      filters.push('Lieferanten');
    }
    if (this.getSearchData.warengruppe?.length > 0) {
      filters.push('Warengruppen');
    }
    if (this.getSearchData.modelnames?.length > 0) {
      filters.push('Modellname');
    }
    if (this.getSearchData.deltaexport?.length > 0) {
      filters.push('Deltaexport');
    }

    if (this.getSearchData.preisbindungBis?.length > 0) {
      filters.push('PreisbindungBis');
    }
    if (this.getSearchData.lastUpdateOn?.length > 0) {
      filters.push('Änderungsdatum Geändertam'); // both Figma and database filter names
    }
    if (this.getSearchData.erstelltAm?.length > 0) {
      filters.push('Anlagedatum Erstelltam'); // both Figma and database filter names
    }
    if (this.getSearchData.farbeSuchfarbe?.length > 0) {
      filters.push('Farbe');
    }
    if (this.getSearchData.aktionsware?.length > 0) {
      filters.push('Aktionsware');
    }

    let result = filters.join(', ').toLowerCase();
    return result;
  }

  clickClose() {
    this.$emit('click:close');
  }
}
