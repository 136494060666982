import { ActionTree } from 'vuex';
import { ExportTemplateState } from './types';
import { RootState } from '../../types';
import { defaultBackendExportTemplate } from '@/shared/backend/exportTemplate';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import exportTemplate, { Value, ExportTemplate } from '@/shared/model/exportTemplate';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.exportTemplates');
export const actions: ActionTree<ExportTemplateState, RootState> = {
  getExportTemplates({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setExportTemplatesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getExportTemplatesSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendExportTemplate
      .getExportTemplates(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setExportTemplates', response.data);
        commit('setExportTemplatesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setExportTemplatesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getExportTemplate({ commit, dispatch }, id: string) {
    return defaultBackendExportTemplate
      .getExportTemplate(id)
      .then((response: AxiosResponse<ExportTemplate>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateExportTemplate({ commit, dispatch }, file: ExportTemplate) {
    return defaultBackendExportTemplate
      .updateExportTemplate(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ExportTemplate;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.exportTemplate_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteExportTemplate({ commit, dispatch }, id: string) {
    return defaultBackendExportTemplate
      .deleteExportTemplate(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ExportTemplate;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
