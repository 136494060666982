export interface Color {
  id: number; // int
  name: string; // string
}

export interface Value {
  value: Color[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Color>): any {
  return {
    Id: data?.id || 0,
    Name: data?.name || '',
  };
}

function parse(data?: Partial<Color>): Color {
  return {
    id: data?.id || 0,
    name: data?.name || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
