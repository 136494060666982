import { ActionTree } from 'vuex';
import { ExportAutomaticState } from './types';
import { RootState } from '../../types';
import { defaultBackendExportAutomatic } from '@/shared/backend/exportAutomatic';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import exportAutomatic, { Value, ExportAutomatic } from '@/shared/model/exportAutomatic';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.exportAutomatics');
export const actions: ActionTree<ExportAutomaticState, RootState> = {
  getExportAutomatics({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setExportAutomaticsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getExportAutomaticsSearchParams;
    return defaultBackendExportAutomatic
      .getExportAutomatics(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setExportAutomatics', response.data);
        commit('setExportAutomaticsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setExportAutomaticsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getExportAutomatic({ commit, dispatch }, id: string) {
    return defaultBackendExportAutomatic
      .getExportAutomatic(id)
      .then((response: AxiosResponse<ExportAutomatic>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateExportAutomatic({ commit, dispatch }, file: ExportAutomatic) {
    return defaultBackendExportAutomatic
      .updateExportAutomatic(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ExportAutomatic;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.exportAutomatic_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteExportAutomatic({ commit, dispatch }, id: string) {
    return defaultBackendExportAutomatic
      .deleteExportAutomatic(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ExportAutomatic;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
