import { ArticleMarker } from './articleMarker';
import { Color } from './color';
import { ModelMarker } from './modelMarker';

export interface ExportArchive {
  id: string; // System.Guid
  name: string; // string
  filters: string; // string
  exportType: string; // piex.DataAccessPostgreSQL.Database.DataModel.Enums.ExportEnums.ExportType
  exportFileFormat: string;
  lastExport: string; // System.DateTime
  selectionId: string; // System.Guid?
  // selection: Selection; // SelectionDm
  modelMarkers: ModelMarker[];
  articleMarkers: ArticleMarker[];
  colors: Color[];

  produktgruppen: string[]; // string[]
  lieferantenNummers: number[]; // int[]
  warengruppen: string[]; // string[]
  modelNames: string[]; // string[]
  articleNames: string[]; // string[]
  deltaExport: string; // System.DateTime?
  preisbindungBis: string; // System.DateTime?
  geaendertAm: string; // System.DateTime?
  erstelltAm: string; // System.DateTime?
  createdAt: string; // System.DateTime
  isArticlesWithAllStatuses: boolean;

  isSentByEmail: boolean;
  userName: string;
  userEmail: string;

  customFields: string;
  customTemplateName: string;
}

export interface Value {
  value: ExportArchive[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ExportArchive>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Filters: data?.filters || '',

    ExportType: data?.exportType || undefined,
    ExportFileFormat: data?.exportFileFormat || '',
    LastExport: data?.lastExport || undefined,
    SelectionId: data?.selectionId || '',
    ModelMarkers: data?.modelMarkers || [],
    ArticleMarkers: data?.articleMarkers || [],
    Colors: data?.colors || [],

    // Selection: data?.selection || '',
    Produktgruppen: data?.produktgruppen || [''],
    LieferantenNummers: data?.lieferantenNummers || [],
    Warengruppen: data?.warengruppen || [],
    ModelNames: data?.modelNames || [],
    ArticleNames: data?.articleNames || [],
    DeltaExport: data?.deltaExport || undefined,
    PreisbindungBis: data?.preisbindungBis || undefined,
    GeaendertAm: data?.geaendertAm || undefined,
    ErstelltAm: data?.erstelltAm || undefined,
    CreatedAt: data?.createdAt || undefined,
    IsArticlesWithAllStatuses: data?.isArticlesWithAllStatuses || false,

    IsSentByEmail: data?.isSentByEmail || false,
    UserName: data?.userName || '',
    // Domain: window.location.origin || '',
    UserEmail: data?.userEmail || '',
    CustomFields: data?.customFields || '',
    CustomTemplateName: data?.customTemplateName || '',

  };
}

function parse(data?: Partial<ExportArchive>): ExportArchive {
  return {
    id: data?.id || '',
    name: data?.name || '',
    filters: data?.filters || '',
    exportType: data?.exportType || '',
    exportFileFormat: data?.exportFileFormat || '',
    lastExport: data?.lastExport || '',
    selectionId: data?.selectionId || '',
    modelMarkers: data?.modelMarkers || [],
    articleMarkers: data?.articleMarkers || [],
    colors: data?.colors || [],

    // selection: data?.selection || '',
    produktgruppen: data?.produktgruppen || [],
    lieferantenNummers: data?.lieferantenNummers || [],
    warengruppen: data?.warengruppen || [],
    modelNames: data?.modelNames || [],
    articleNames: data?.articleNames || [],
    deltaExport: data?.deltaExport || '',
    preisbindungBis: data?.preisbindungBis || '',
    geaendertAm: data?.geaendertAm || '',
    erstelltAm: data?.erstelltAm || '',
    createdAt: data?.createdAt || '',
    isArticlesWithAllStatuses: data?.isArticlesWithAllStatuses || false,

    isSentByEmail: data?.isSentByEmail || false,
    userName: data?.userName || '',
    // domain: window.location.origin || '',
    userEmail: data?.userEmail || '',
    customFields: data?.customFields || '',
    customTemplateName: data?.customTemplateName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
