import { ActionTree } from 'vuex';
import { ArticleState } from './types';
import { RootState } from '../../types';
import { defaultBackendArticle } from '@/shared/backend/article';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import article, { Value, Article } from '@/shared/model/article';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('actions.articles');
export const actions: ActionTree<ArticleState, RootState> = {
  getArticlesProductGroups({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesProductGroups(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getArticlesProductGroups response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesProductGroups', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesWarengruppe({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesWarengruppe(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getArticlesWarengruppe response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesWarengruppe', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesModelNames({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesModelNames(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getArticlesModelNames response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesModelNames', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesNames({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesNames(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getArticlesNames response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesNames', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesSuppliers({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesAllSuppliers(payload)
      .then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesSuppliers', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesReport(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; searchData?: any }) {
    commit('setArticlesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getArticlesSearchParams;
    let searchData = payload?.searchData ?? {};
    return defaultBackendArticle
      .getArticles(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        commit('setArticles', response.data);
        commit('setArticlesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setArticlesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticle({ commit, dispatch }, id: string) {
    return defaultBackendArticle
      .getArticle(id)
      .then((response: AxiosResponse<Article>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateArticle({ commit, dispatch }, file: Article) {
    return defaultBackendArticle
      .updateArticle(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Article;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.article_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteArticle({ commit, dispatch }, id: string) {
    return defaultBackendArticle
      .deleteArticle(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Article;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateSearchData({ commit, dispatch }, data: any) {
    commit('setSearchData', data);
  },

  resetSearchFormData({ commit, dispatch }, data: any) {
    commit('resetSearchFormData', data);
  },
};
